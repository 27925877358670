<template>
  <div>
    <v-card class="mt-4">
      <v-row no-gutters>
        <v-col
          class="mt-4"
          v-if="$vuetify.breakpoint.name != 'xs'"
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xl="12"
        >
          <v-toolbar dense elevation="0">
            <v-select
              label="Filter by Year"
              v-model="selectedYear"
              :disabled="isLoading == true"
              @change="get_swipe_data_yearfiletr()"
              :items="filteredYears"
              class="mr-2"
              style="max-width: 180px"
              dense
              outlined
            >
            </v-select>
            <v-select
              label="Filter by Month"
              :disabled="isLoading == true"
              item-text="text"
              item-value="value"
              v-model="selectedMonth"
              @change="get_swipe_data_report()"
              v-on:change="get_all_days_of_month()"
              :items="formattedMonthItems"
              dense
              style="max-width: 180px"
              outlined
              class="ml-2"
            >
            </v-select>
            <v-text-field
              label="Search"
              class="ml-2"
              v-model="search"
              style="max-width: 220px"
              dense
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="12">
          <v-toolbar dense class="elevation-0">
            <v-select
              label="Filter by Year"
              v-model="selectedYear"
              :disabled="isLoading == true"
              @change="get_swipe_data_yearfiletr()"
              :items="filteredYears"
              class="mr-2"
              dense
              outlined
            >
            </v-select>
            <v-select
              label="Filter by Month"
              :disabled="isLoading == true"
              item-text="month"
              item-value="monthvalue"
              v-model="selectedMonth"
              @change="get_swipe_data_report()"
              v-on:change="get_all_days_of_month()"
              :items="formattedMonthItems"
              dense
              outlined
            >
            </v-select>
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="12">
          <v-toolbar dense class="elevation-0">
            <v-text-field
              label="Search"
              v-model="search"
              dense
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="SwipeDataHeaders"
          :items="SwipeDataItems"
          dense
          :search="search"
          :height="height"
          class="overflow-y-auto overflow"
          :fixed-header="fixedHeader"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          :items-per-page="100"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text alertcss"
              >No Data available for the selected Year & Month.
            </v-alert>
          </template>
          <template
            v-for="col in days_in_current_month"
            v-slot:[`item.day${col}`]="{ item }"
          >
            <WorkingHolidayChip :key="col" :item="item.monthly[col - 1]" />
          </template>
          <template v-slot:[`item.is_no_of_holiday_present`]="{ item }">
            <span v-if="item != undefined"
              >H: {{ item.is_no_of_holiday_present }} W/O:
              {{ item.is_no_of_week_off_present }}</span
            >
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import WorkingHolidayChip from "./WorkingHolidayChip.vue";
import { API, graphqlOperation } from "aws-amplify";
import { getWorkOnOffDays } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
export default {
  components: {
    WorkingHolidayChip,
    Overlay,
  },

  data: () => ({
    search: "",
    fixedHeader: true,
    isLoading: false,
    overlay: false,
    SwipeDataHeaders: [],
    SwipeDataItems: [],
    selectedYear: new Date().getFullYear(),
    selectedMonth: "",
    formattedMonthItems: [],

    days_in_current_month: 0,

    new_array: [],
    selectedDate: "",
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  }),
  computed: {
    filteredYears() {
      const currentYear = new Date().getFullYear();
      var dateComponents = this.selectedDate.split(/[\/,\s:]+/);
      // Extracting components
      var month = parseInt(dateComponents[0], 10) - 1; // Month is zero-indexed in JavaScript Date
      var day = parseInt(dateComponents[1], 10);
      var year = parseInt(dateComponents[2], 10);
      var hour = parseInt(dateComponents[3], 10);
      var minute = parseInt(dateComponents[4], 10);
      var second = parseInt(dateComponents[5], 10);
      var period = dateComponents[6]; // "AM" or "PM"

      // Adjust hour based on AM/PM
      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }

      var date = new Date(year, month, day, hour, minute, second);

      const selectedDateYear = date.getFullYear();
      const years = [];

      for (let year = selectedDateYear; year <= currentYear; year++) {
        years.push(year);
      }
      return years;
    },
  },
  async created() {
    this.isLoading = true;
    this.overlay = true;
    this.height = window.innerHeight - 220;

    await this.get_swipe_data_report();
    await this.get_all_days_of_month();
  },
  async mounted() {
    this.selectedDate = this.get_date(
      this.$store.getters.get_org_details[0].organisation_created_on
    );
    //  this.selectedDate='03/02/2024, 03:35:44 PM';

    await this.month_get();
  },
  watch: {},
  methods: {
    async get_swipe_data_yearfiletr() {
      await this.month_get();
      await this.get_all_days_of_month();
      await this.get_swipe_data_report();
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    month_get() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonthIndex = currentDate.getMonth();

      let newMonthItems = [];

      const month = this.selectedDate.match(/^(\d{2})\//)[1];
      // if (this.selectedYear === new Date(this.selectedDate).getFullYear()) {

      //   newMonthItems = this.monthNames.slice();
      // }

      if (this.selectedYear < currentYear) {
        newMonthItems = this.monthNames.slice();
      } else if (this.selectedYear === currentYear) {
        newMonthItems = this.monthNames.slice(0, currentMonthIndex + 1);
      } else {
        newMonthItems = this.monthNames.slice();
      }

      this.monthItems = newMonthItems.slice();

      this.formattedMonthItems = this.monthItems.map((month, index) => ({
        text: month,
        value: (index + 1).toString().padStart(2, "0"),
      }));
      this.selectedMonth = (currentMonthIndex + 1).toString().padStart(2, "0");
      if (this.selectedYear == new Date(this.selectedDate).getFullYear()) {
        const startIndex = this.formattedMonthItems.findIndex(
          (item) => item.value === month
        );

        const filteredArray =
          startIndex !== -1 ? this.formattedMonthItems.slice(startIndex) : [];

        this.formattedMonthItems = [];
        this.formattedMonthItems = filteredArray;
        this.selectedMonth = month;
      }

      this.$forceUpdate();
    },
    get_all_days_of_month() {
      this.SwipeDataHeaders = [];
      this.SwipeDataHeaders = [
        { text: "Member Id", value: "employee_id" },
        { text: "Member Name", value: "user_name" },
        { text: "#Days Worked", value: "is_no_of_holiday_present" },
        // { text: "#W/O", value: "is_no_of_week_off_present" },
      ];

      const daysInMonth = new Date(
        this.selectedYear,
        this.selectedMonth,
        0
      ).getDate();
      this.days_in_current_month = daysInMonth;

      for (let i = 1; i <= daysInMonth; i++) {
        this.SwipeDataHeaders.push({
          text: i,
          value: `day${i}`,
          sortable: false,
          width: "auto",
        });
      }
    },
    async get_swipe_data_report() {
      this.SwipeDataItems = [];
      this.overlay = true;
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(getWorkOnOffDays, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              year: this.selectedYear.toString(),
              month: this.selectedMonth,
            },
          })
        ).then((res) => {
          this.SwipeDataItems =
            JSON.parse(res.data.getWorkOnOffDays) != null
              ? JSON.parse(res.data.getWorkOnOffDays)
              : [];
          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        this.SwipeDataItems = [];
        console.log(err);
        this.overlay = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
