<template>
  <div v-if="item!=undefined">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          small
          :color="item.has_worked_extra_type == 'W/O' ? 'primary' : 'purple'"
          
           
        >{{ item != undefined &&
            item.first_swipe != undefined &&
            item.last_swipe != undefined
              ? item.has_worked_extra_type == 'W/O'
                ? 'mdi-wikipedia'
                : 'mdi-signal-hspa'
              : ''
           }}
        </v-icon>
      </template>
      <span class="white--text"
        >First Swipe:
        {{
          item.first_swipe != undefined && item.last_swipe != undefined
            ? new Date(item.first_swipe * 1000)
                .toLocaleTimeString()
                .toUpperCase()
            : ""
        }}
        <br />
        Last Swipe:
        {{
          item.first_swipe != undefined && item.last_swipe != undefined
            ? new Date(item.last_swipe * 1000)
                .toLocaleTimeString()
                .toUpperCase()
            : ""
        }}
        <br />
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  // watch: {
  //   item(val) {
  //     console.log("item", val);
  //   },
  // },
};
</script>

<style></style>
